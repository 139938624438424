@media print {
  .no-print {
    display: none;
  }

  * {
    color: #000 !important;
    overflow: visible !important;
  }

  header[role="banner"], main[role="main"], footer[role="contentinfo"] {
    max-width: none !important;
    margin: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .pa3-print {
    padding: 1rem !important;
  }

  .shadow-1, .shadow-2, .shadow-3, .shadow-4, .shadow-5 {
    box-shadow: none !important;
  }

  #root > div {
    min-width: auto !important;
    max-width: 100%;
  }

  .w-50-print {
    width: 50% !important;
    }

  .no-break {
    page-break-inside: avoid;
  }

  /* Usage summaries: override with float styling because page-break-avoid
   * doesn't work with flexbox layout */
  #usage-summaries {
    display: block !important;
  }

  #usage-summaries:before,
  #usage-summaries:after {
    content: " ";
    display: table;
  }
  #usage-summaries:after {
    clear: both;
  }

  #usage-summaries > div {
    display: block !important;
    float: left;
    page-break-inside: avoid;
  }

  #usage-summaries > div:nth-child(2n + 1) {
    clear: left;
  }
}
